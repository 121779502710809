import React from 'react';
import { firebase, next } from '@vette/frontend-utils';
import { client } from '@vette/pages';
import { DashboardLayout } from '@vette/ui-components';
import { ClientUserPermissionEnum, UserRoleEnum } from '@vette/data-access';

/**
 * @returns For Client Interviewers and Admins coming through enterprise app: DashboardInternalVetter
 * For Client Managers and Admins coming through admin app > Clients > Manage: Dashboard
 */
const Dashboard: React.FC = () => {
  return (
    <DashboardLayout hasStatusToggle overflowY="auto">
      <firebase.FirebaseClaims
        required={{ id: true, role: true, clientId: true }}
      >
        {({ clientId, id, clientUserPermission, role }) => (
          <next.RouterQuery allowEmpty>
            {queryParams =>
              clientUserPermission === ClientUserPermissionEnum.MANAGE ||
              (role === UserRoleEnum.ADMIN && queryParams.cliendId) ? (
                <client.Dashboard clientId={clientId} userId={id} />
              ) : clientUserPermission === ClientUserPermissionEnum.INTERVIEW ||
                (role === UserRoleEnum.ADMIN && !queryParams.cliendId) ? (
                <client.DashboardInternalVetter
                  clientId={clientId}
                  userId={id}
                />
              ) : null
            }
          </next.RouterQuery>
        )}
      </firebase.FirebaseClaims>
    </DashboardLayout>
  );
};
export default Dashboard;
