import React from 'react';
import { Card, Space, Text, theme, Title } from '@vette/ui-components';
import { useUserPresenceQuery, UserPresenceEnum } from '@vette/data-access';
import { useCustomRouter, message } from '@vette/frontend-utils';
import { StatsChart } from './StatsChart';
import { ApplicantsList } from './ApplicantsList';

import * as S from './styles';

type Props = {
  userId: string;
  clientId: string;
};

export const DashboardInternalVetter: React.FC<Props> = ({
  userId,
  clientId,
}) => {
  const { router } = useCustomRouter();
  const { data: vetterData, loading } = useUserPresenceQuery({
    variables: { userId },
    onError: () => {
      message.error(
        'Failed to obtain information about your dashboard. Please, make sure your network is reliably working.'
      );
    },

    onCompleted: data => {
      if (!data.user) {
        message.error(`You are not authorized to access this page.`);
        router.replace('/404');
        return;
      }
    },
  });

  return (
    <Space
      className="dashboard-interviewer"
      $padding="100px 36px"
      direction="vertical"
      size={53}
    >
      <S.LoadingHidden isLoading={loading}>
        <S.Profile href="/settings">
          <Text $color={theme.colors.white} $size={15} $weight={600}>
            {vetterData?.user?.firstName} {vetterData?.user?.lastName}
          </Text>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={vetterData?.user?.photoHighUrl || '/images/avatar.svg'}
            alt="profile"
          />
        </S.Profile>
      </S.LoadingHidden>
      <S.LoadingHidden isLoading={loading}>
        <Title $color={theme.colors.white} level={1}>
          Hi {vetterData?.user?.firstName}!
        </Title>
      </S.LoadingHidden>
      <Space $fullWidth direction="vertical" size={22}>
        <S.CoolCard
          disableFillAvailableHeight
          innerRowFullWidth
          noMaxWidth
          disableMargins
          disablePaddings
        >
          <StatsChart clientId={clientId} userId={userId} />
          <S.CirclesImage
            $active={
              vetterData?.user?.presence === UserPresenceEnum.BUSY ||
              vetterData?.user?.presence === UserPresenceEnum.ONLINE
            }
          />
        </S.CoolCard>

        <Card
          disableFillAvailableHeight
          innerRowFullWidth
          noMaxWidth
          disableMargins
        >
          <ApplicantsList userId={userId} clientId={clientId} />
        </Card>
      </Space>
    </Space>
  );
};
