import styled from 'styled-components';
import { Text, Title, Button as Btn } from '@vette/ui-components';
import { Skeleton } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QuestionCircleFilled from '@ant-design/icons/QuestionCircleFilled';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 36px;
  overflow-y: auto;
  z-index: 1;
`;

export const Header = styled.div`
  display: flex;
  flex: 0 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 84px 48px 0;
`;

export const Heading = styled(Title)`
  && {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 1.4fr;
  gap: 24px;
  margin-top: 24px;
  padding: 36px;
  max-height: 400px;
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  padding: 37px 34px 40px 33px;
  flex-direction: column;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  height: 387px;
`;

export const PrimaryText = styled(Title)<{ $color?: string; $size?: number }>`
  && {
    font-size: ${({ $size }) => ($size ? $size : '64')}px;
    line-height: 120%;
    color: ${({ theme, $color }) => ($color ? '$color' : theme.colors.grey100)};
  }
  text-align: center;
`;

export const Label = styled(Text)<{ $color?: string; $align?: boolean }>`
  color: ${({ theme, $color }) => ($color ? '$color' : theme.colors.grey100)};
  text-align: ${({ $align }) => ($align ? 'center' : '')};
`;

export const SkeletonCard = styled(Skeleton).attrs({
  title: true,
  paragraph: false,
  active: true,
})`
  display: flex;
  flex: 1;
  .ant-skeleton-title {
    border-radius: 18px;
    height: 100%;
    display: flex;
    flex: 1;
    margin-top: 0;
  }
  height: 387px;
`;

export const DropdownOutlined = styled(DownOutlined)`
  stroke-width: 100 !important;
  stroke: black !important;
`;

export const Button = styled(Btn)`
  width: 166px;
  height: 48px;
`;

export const CardBody = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 106px;
  display: flex;
  gap: 4px;
`;

export const ImageWrap = styled.div<{
  $src: string;
  offsetY?: string;
  backgroundSize?: 'contain';
  offsetX?: string;
  grayscale?: boolean;
  opacity?: number;
}>`
  flex: 1;
  width: 100%;
  background: url(${({ $src }) => $src}) no-repeat center center;
  ${({ offsetY }) => `background-position-y: ${offsetY}`};
  ${({ backgroundSize }) => `background-size: ${backgroundSize}`};
  ${({ offsetX }) => `background-position-x: ${offsetX}`};
  ${({ grayscale }) => ` ${grayscale ? 'filter: grayscale(1)' : ''} `};
  ${({ opacity }) => `opacity: ${opacity}`};
`;

export const QuestionIcon = styled(QuestionCircleFilled)`
  color: ${({ theme }) => theme.colors.grey60};
  font-size: 10px;
  vertical-align: initial;

  &:hover {
    cursor: pointer;
  }
`;

export const InfoIcon = styled(InfoCircleFilled)`
  color: ${({ theme }) => theme.colors.grey30};
  font-size: 10px;
`;

export const lastCard = styled.div`
  display: flex;
  flex: 1;
  gap: 27px;
  padding-top: 40px;
  padding-left: 40px;
  flex-direction: column;
  border-radius: 18px;
  background: linear-gradient(136.75deg, #000000 22.72%, #251444 74.25%);
  height: 387px;
`;
